
import React from 'react';
import { Button, Navbar, Nav, Col, Row, Container, Offcanvas  } from 'react-bootstrap';
import ArrowRight from 'remixicon-react/ArrowRightLineIcon';
import { ReactComponent as Logo } from './assets/img/logo.svg';
import { ReactComponent as Home } from './assets/img/home.svg';
import { ReactComponent as HomeBg } from './assets/img/homebg.svg';
import { ReactComponent as Near } from './assets/img/near.svg';
import { ReactComponent as Polygon } from './assets/img/polygon.svg';
import { ReactComponent as Bsc } from './assets/img/bsc.svg';
import { ReactComponent as Btc } from './assets/img/btc.svg';
import { ReactComponent as Aurora } from './assets/img/aurora.svg';
import { ReactComponent as Eth } from './assets/img/eth.svg';
import { ReactComponent as Feature1 } from './assets/img/feature1.svg';
import { ReactComponent as Feature2 } from './assets/img/feature2.svg';
import { ReactComponent as Feature3 } from './assets/img/feature3.svg';
import { ReactComponent as FeatureIcon1 } from './assets/img/featureicon1.svg';
import { ReactComponent as FeatureIcon2 } from './assets/img/featureicon2.svg';
import { ReactComponent as FeatureIcon3 } from './assets/img/featureicon3.svg';
import { ReactComponent as SecurityIcon1 } from './assets/img/securityicon1.svg';
import { ReactComponent as SecurityIcon2 } from './assets/img/securityicon2.svg';
import { ReactComponent as SecurityIcon3 } from './assets/img/securityicon3.svg';
import { ReactComponent as SecurityIcon4 } from './assets/img/securityicon4.svg';
import { ReactComponent as Delivery1 } from './assets/img/delivery1.svg';
import { ReactComponent as Delivery2 } from './assets/img/delivery2.svg';
import { ReactComponent as Delivery3 } from './assets/img/delivery3.svg';
import { ReactComponent as Delivery4 } from './assets/img/delivery4.svg';
import { ReactComponent as MeteorPlain } from './assets/img/meteor_plain.svg';
import { ReactComponent as Chrome } from './assets/img/chrome.svg';
import { ReactComponent as Web } from './assets/img/web.svg';
import { ReactComponent as PlayStore } from './assets/img/playstore.svg';
import { ReactComponent as AppStore } from './assets/img/appstore.svg';
import { ReactComponent as Abstract1 } from './assets/img/abstract1.svg';
import { ReactComponent as Abstract2 } from './assets/img/abstract2.svg';
import { ReactComponent as Abstract3 } from './assets/img/abstract3.svg';
import { ReactComponent as Abstract4 } from './assets/img/abstract4.svg';
import { ReactComponent as Abstract5 } from './assets/img/abstract5.svg';
import Backed1 from './assets/img/backed1.png';
import Backed2 from './assets/img/backed2.png';
import Backed3 from './assets/img/backed3.png';
import Backed4 from './assets/img/backed4.png';
import Backed5 from './assets/img/backed5.png';
import Backed6 from './assets/img/ov.png';
import Community from './assets/img/community.png';
import Community1 from './assets/img/c1.png';
import Community2 from './assets/img/c2.png';
import Community3 from './assets/img/c3.png';
import Community4 from './assets/img/c4.png';
import Community5 from './assets/img/c5.png';
import Community6 from './assets/img/c6.png';
import Community7 from './assets/img/c7.png';
import Community8 from './assets/img/c8.png';
import Community9 from './assets/img/c9.png';
import BGGradient1 from './assets/img/bggradient1.png';
import BGGradient2 from './assets/img/bggradient2.png';
import BGGradient3 from './assets/img/bggradient3.png';
import BGGradient4 from './assets/img/bggradient4.png';
import BGGradient5 from './assets/img/bggradient5.png';
import BGGradient6 from './assets/img/bggradient6.png';
import BGGradient7 from './assets/img/bggradient7.png';
import BGGradient8 from './assets/img/bggradient8.png';
import Twitter from './assets/img/twitter.png';
import Discord from './assets/img/discord.png';
import Telegram from './assets/img/telegram.png';

import './style/index.css';
import WOW from 'wowjs';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gleap from "gleap";


const gleap_api_key = "NzgTXE1VhwYvyOe0rCt5P3b3eEoDQGwk";

Gleap.initialize(gleap_api_key);

  
class HomePage extends React.Component {

    // Defining WOW 
    componentDidMount() {
      new WOW.WOW({
        live: false
    }).init();
    }
    handleClick = () => {
        Gleap.open();
    };
    
  
        
    render() {

        var settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1.1,
            slidesToScroll: 1,
            
          };
        return (
            <div id="content">
                <Navbar fixed="top"  expand="xl" id='topnav' >
                    <Container>
                        <Navbar.Brand href="/"><Logo/></Navbar.Brand>
                        <a className='mobileOpenWallet' href='https://wallet.meteorwallet.app/' target="_blank"><Button variant="primary">Open Wallet</Button> </a>

                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-lg`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                            placement="end"
                        >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <Logo/> 
                            </Offcanvas.Title>
                                <a className='offcanvasopenwallet' href='https://wallet.meteorwallet.app/' target="_blank"><Button variant="primary">Open Wallet</Button> </a>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div></div>
                            <Nav className="justify-content-center flex-grow-1 pe-3 gap-5">
                                <Nav.Link href="https://github.com/Meteor-Wallet/meteor-public/blob/main/compliance/compliance-overview.md" target="_blank">Security</Nav.Link>
                                <Nav.Link onClick={this.handleClick}>Support</Nav.Link>
                                <Nav.Link href="https://github.com/Meteor-Wallet/meteor-public" target="_blank">Integration</Nav.Link>
                            </Nav>
                            <a className='desktopopenwallet' href='https://wallet.meteorwallet.app/' target="_blank"><Button variant="primary">Open Wallet</Button> </a>
                            <div id='offcanvas-social'>
                                <div id='offcanvas-social-items'>
                                    <a  href="https://twitter.com/MeteorWallet" target="_blank">
                                        <img src={Twitter}/>
                                    </a>
                                    <a href="https://discord.com/invite/9D5V5j5nWr" target="_blank">
                                        <img src={Discord}/>
                                    </a>
                                    <a href="https://t.me/MeteorWalletGroup" target="_blank">
                                        <img src={Telegram}/>
                                    </a>
                                </div>
                                <h6>Copyright © 2024 METEOR LAB PTE. LTD. All Rights Reserved </h6>
                            </div>
                        </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>

                <Container id='landingSection' >
                    <Row>
                        <Col lg="6" id='landingLeft'>
                            <div id='trusted' >
                                <h6> <div className='circle'></div> Trusted by over 1 000 000 Users </h6>
                            </div>
                            <h1 className='landingTitle'> The last wallet <br/>you will ever need </h1>
                            <p className='landingSubText desktop'> The most simple and secure wallet to manage your <br/>crypto, access DeFi, and explore Web3 </p>
                            <p className='landingSubText mobile'> The most simple and secure wallet to <br/>manage your crypto, access DeFi, and <br/>explore Web3 </p>
                            <a href='https://wallet.meteorwallet.app/' target="_blank"> <Button variant="primary">Open Wallet <ArrowRight id='arrowRight'/></Button> </a>
                            <div className='mt-6 wows fadeInUp landingBacked desktoplandingbacked' >
                                <h6> Backed by </h6>
                                <div className='backedSection'>
                                    <div className='backedItem'>
                                        <a href='https://near.foundation/' target="_blank"><img src={Backed1}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://www.proximity.dev/' target="_blank"><img src={Backed5}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://orderly.network/' target="_blank"><img src={Backed4}/></a>
                                    </div>
                                 
                                    <div className='backedItem'>
                                        <a href='https://metavest.capital/' target="_blank"><img src={Backed3}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://twitter.com/bantercap' target="_blank"><img src={Backed2}/></a>
                                    </div>
                                    <div className='backedItem ov'>
                                        <a href='https://outlierventures.io/' target="_blank"><img src={Backed6}/></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" id='landingRight' >
                            <div id='homeBgBox'>
                                <HomeBg id='homebg'/>
                            </div>
                            <Home id='homeimg'/>
                            <div className='mt-6 wows fadeInUp landingBacked mobilelandingbacked' >
                                <h6> Backed by </h6>
                                <div className='backedSection'>
                                    <div className='backedItem'>
                                        <a href='https://near.foundation/' target="_blank"><img src={Backed1}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://www.proximity.dev/' target="_blank"><img src={Backed5}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://orderly.network/' target="_blank"><img src={Backed4}/></a>
                                    </div>
                                    <div className='backedItem ov'>
                                        <a href='https://outlierventures.io/' target="_blank"><img src={Backed6}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://metavest.capital/' target="_blank"><img src={Backed3}/></a>
                                    </div>
                                    <div className='backedItem'>
                                        <a href='https://twitter.com/bantercap' target="_blank"><img src={Backed2}/></a>
                                    </div>
                                  
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <img src={BGGradient1} id='bgGradient1'/>
                    <img src={BGGradient3} id='bgGradient3'/>

                </Container>

                <Container  id='chainSection' className='wows fadeInUp'  data-wow-duration="0.8s">
                    <Abstract1 id='abstract1'/>
                    <img src={BGGradient2} id='bgGradient2'/>
                    <div id='chainBox' className='innerContainer'>
                        <h2 className='mb-5'>
                            Multi-chain Wallet
                        </h2>
                        <div id='chainLogoSection'>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Near/>
                                </div>
                                <p className='bold white'>
                                    Near
                                </p>
                                <h6>
                                    active
                                </h6>
                            </div>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Aurora/>
                                </div>
                                <p className='bold white'>
                                    Aurora
                                </p>
                                <h6>
                                    soon
                                </h6>
                            </div>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Eth/>
                                </div>
                                <p className='bold white'>
                                    Ethereum
                                </p>
                                <h6>
                                    soon
                                </h6>
                            </div>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Bsc/>
                                </div>
                                <p className='bold white'>
                                    BSC
                                </p>
                                <h6>
                                    soon
                                </h6>
                            </div>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Polygon/>
                                </div>
                                <p className='bold white'>
                                    Polygon
                                </p>
                                <h6>
                                    soon
                                </h6>
                            </div>
                            <div className='chainLogoItem'>
                                <div className='chainLogo'>
                                    <Btc/>
                                </div>
                                <p className='bold white '>
                                    Bitcoin
                                </p>
                                <h6>
                                    soon
                                </h6>
                            </div>
                        </div>
                    </div>
                </Container>


                <Container id='featureSection'>
                    <div className='innerContainer'>
                    <img src={BGGradient4} id='bgGradient4'/>
                    <div className='desktop'>
                    <Row  className="justify-content-between ">
                        <Col lg={"5"}  xxl={"4"} id='featureLeft' className='wows fadeInLeft'>
                            <h3>Elevate your wallet <br/>experience with <br/>powerful features</h3>
                         
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon2/>
                                    <p className='bold'>Create your named <br/>wallet for free</p>
                                </div>
                                <Feature2/>
                            </div>
                            <Abstract2 id='abstract2' className='desktop'/>
                        </Col>
                        <Col  lg={"5"} xxl={"4"} id='featureRight' className='wows fadeInRight'>
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon1/>
                                    <p className='bold'>Get the fastest swaps <br/>with the lowest fees</p>
                                </div>
                                <Feature1/>
                            </div>
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon3/>
                                    <p className='bold'>Learn and earn with <br/>challenges and rewards</p>
                                </div>
                                <Feature3/>
                            </div>
                        </Col>
                    </Row>
                    </div>
                    <div className='mobile'>
                        <h3 >Elevate your wallet <br/>experience with powerful <br/>features</h3>
                        <Slider {...settings}>
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon2/>
                                    <p className='bold'>Create your named <br/>wallet for free</p>
                                </div>
                                <Feature2/>
                            </div>
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon1/>
                                    <p className='bold'>Get the fastest swaps <br/>with the lowest fees</p>
                                </div>
                                <Feature1/>
                            </div>
                            <div className='featureItem'>
                                <div className='featureTop'>
                                    <FeatureIcon3/>
                                    <p className='bold'>Learn and earn with <br/>challenges and rewards</p>
                                </div>
                                <Feature3/>
                            </div>
                        </Slider>
                    </div>
                    </div>
                </Container>

                <Container  id='securitySection' className='wows fadeInUp'>
                    <div className='innerContainer'>
                    <img src={BGGradient5} id='bgGradient5'/>
                    <Row>
                        <Col xxl={4} lg={4}>
                            <h3 className='desktop' >How Meteor <br/>keeps you and <br/>your crypto safe</h3>
                            <h3 className='mobile' >How Meteor keeps you <br/>and your crypto safe</h3>
                            <p className='mb-0'> You’ll never have to worry about  <br/>the safety of your funds</p>
                            <Abstract3 id='abstract3'/>
                        </Col>
                        <Col xxl={1} className='h'></Col>
                        <Col xxl={7} lg={8}>
                            <Row className='securityRow'>
                                <Col lg={6}>
                                    <div className='securityItem'>
                                        <h5><SecurityIcon1/>Non-custodial</h5>
                                        <p className='small desktop'>
                                            We never have access to <br/>your private keys or funds
                                        </p>
                                        <p className='small mobile'>
                                            We never have access to your  <br/>private keys or funds
                                        </p>
                                    </div> 
                                </Col>
                                <Col lg={6}>
                                    <div className='securityItem'>
                                        <h5><SecurityIcon2/>Privacy</h5>
                                        <p className='small desktop'>
                                        We do not track any  <br/>personally identifiable  <br/>information
                                        </p>
                                        <p className='small mobile'>
                                        We do not track any personally  <br/>identifiable information
                                        </p>
                                    </div> 
                                </Col>
                                <Col lg={6}>
                                    <div className='securityItem'>
                                        <h5><SecurityIcon3/>Fully Encrypted</h5>
                                        <p className='small desktop'>
                                        Your wallet keys are always  <br/>encrypted, accessible only  <br/>to you
                                        </p>
                                        <p className='small mobile'>
                                        Your wallet keys are always  <br/>encrypted, accessible only to you
                                        </p>
                                    </div> 
                                </Col>
                                <Col lg={6}>
                                    <div className='securityItem'>
                                        <h5><SecurityIcon4/>Ledger Support</h5>
                                        <p className='small'>
                                            Coming Soon
                                        </p>
                                       
                                    </div> 
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </div>
                </Container>

                <Container  id='deliverySection' className='wows fadeInUp'>
                    <Abstract2 id='abstract22' className='mobile'/>
                    <div className='innerContainer'>
                        <div id='deliveryHeader'>
                            <h3 className='desktop'>Delivering outstanding support</h3>
                            <h3 className='mobile'>Delivering <br/>outstanding support</h3>
                            <p> We’re here to help and listen to your feedback</p>
                        </div>
                        <div className='deliveryBox'>
                             <div className='deliveryItemBox'>
                                <div className='deliveryItem'>
                                    <div>
                                        <Delivery1/>
                                    </div>
                                    <div>
                                        <h4 className='desktop'> Ticket Support </h4>
                                        <h4 className='mobile'> Ticket  <br/>Support </h4>
                                        <p className='small desktop'>
                                            Reach out quickly via email <br/>and in-wallet
                                        </p>
                                        <p className='small mobile'>
                                            Reach out quickly via  <br/>email and in-wallet
                                        </p>
                                    </div>
                                </div>
                            </div>
                             <div className='deliveryItemBox'>
                                <div className='deliveryItem'>
                                    <div>
                                    <Delivery2/>
                                    </div>
                                    <div>
                                        <h4> User Guide </h4>
                                        <p className='small desktop'>
                                            Comprehensive guide for <br/>beginners and advanced users
                                        </p>
                                        <p className='small mobile'>
                                            Comprehensive  <br/>guide for beginners  <br/>and advanced users
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='deliveryItemBox'>
                                 <div className='deliveryItem dark'>
                                    <div>
                                    <Delivery3/>
                                    </div>
                                    <div>
                                        <h4 className='desktop'> Product Roadmaps </h4>
                                        <h4 className='mobile'> Product <br/>Roadmaps </h4>
                                        <p className='small desktop'>
                                            Transparent and public
                                        </p>
                                        <p className='small mobile'>
                                            Transparent and <br/>public
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='deliveryItemBox'>
                                 <div className='deliveryItem dark'>
                                    <div>
                                        <Delivery4/>
                                    </div>
                                    <div>
                                        <h4 className='desktop'> Feature Requests  </h4>
                                        <h4 className='mobile'> Feature <br/>Requests </h4>
                                        <p className='small desktop'>
                                            Let the community vote on <br/>future features
                                        </p>
                                        <p className='small mobile'>
                                            Let the community <br/>vote on future <br/>features
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Abstract4 id='abstract4'/>
                    <Abstract5 id='abstract5'/>
                </Container>

                <Container  id='communitySection' className='wows fadeInUp'>
                    <img src={BGGradient6} id='bgGradient6'/>
                    <div className='innerContainer'>
                        <div id='communityHeader'>
                            <h3>Delighting our community</h3>
                            <p className='desktop'> Some nice things our wallet users have said about us</p>
                            <p className='mobile'> Some nice things our wallet users <br/>have said about us</p>
                        </div>
                        <div className='desktop'>
                        <Row className='communitydesktoprow'>
                            <Col className='communitydesktopcol'>
                                <a href='https://twitter.com/crypto_groggs/status/1593738205899198464' target='_blank'><img src={Community1}/></a>
                                <a href='https://twitter.com/marketfanatic/status/1593023090761420800' target='_blank'><img src={Community4}/></a>
                                <a href='https://twitter.com/Krikkraktrak/status/1595286929125392384' target='_blank'><img src={Community7}/></a>
                            </Col>
                            <Col className='communitydesktopcol'>
                                <a href='https://twitter.com/CathLabCrypto/status/1596478793949990914' target='_blank'><img src={Community2}/></a>
                                <a href='https://twitter.com/jarednotjerry1/status/1596482884072005635' target='_blank'><img src={Community5}/></a>
                                <a href='https://twitter.com/trevinvsnft/status/1595427793021853696' target='_blank'><img src={Community8}/></a>
                            </Col>
                            <Col className='communitydesktopcol'>
                                <a href='https://twitter.com/KaizenCore/status/1595601644661755905' target='_blank'><img src={Community3}/></a>
                                <a href='https://twitter.com/cuongdc_real/status/1595478111663296513' target='_blank'><img src={Community6}/></a>
                                <a href='https://twitter.com/NihonNFT/status/1593359377381003266' target='_blank'><img src={Community9}/></a>
                            </Col>
                        </Row>
                        </div>
                    </div>
                </Container>
                <div className='communitymobileimagecontainer'>
                    <div className='mobile communitymobileimage '>
                        <a href='https://twitter.com/crypto_groggs/status/1593738205899198464' target='_blank'><img src={Community1}/></a>
                        <a href='https://twitter.com/CathLabCrypto/status/1596478793949990914' target='_blank'><img src={Community2}/></a>
                        <a href='https://twitter.com/KaizenCore/status/1595601644661755905' target='_blank'><img src={Community3}/></a>
                        <a href='https://twitter.com/marketfanatic/status/1593023090761420800' target='_blank'><img src={Community4}/></a>
                        <a href='https://twitter.com/jarednotjerry1/status/1596482884072005635' target='_blank'><img src={Community5}/></a>
                        <a href='https://twitter.com/cuongdc_real/status/1595478111663296513' target='_blank'><img src={Community6}/></a>
                        <a href='https://twitter.com/Krikkraktrak/status/1595286929125392384' target='_blank'><img src={Community7}/></a>
                        <a href='https://twitter.com/trevinvsnft/status/1595427793021853696' target='_blank'><img src={Community8}/></a>
                        <a href='https://twitter.com/NihonNFT/status/1593359377381003266' target='_blank'><img src={Community9}/></a>
                    </div>
                </div>

                <Container  id='trySection' className='wows fadeInUp'>
                    <div id='tryHeader'>
                        <MeteorPlain id='tryLogoAnimation'/>
                        <h1>Try Meteor now!</h1>
                        <p className='desktop'>Get the most out of your web3 journey. <br/> Choose the extension or view your wallet in your favourite browser</p>
                        <p className='mobile'>Get the most out of your web3 journey. <br/> Choose the extension or view your wallet <br/>in your favourite browser</p>
                    </div>
                    <div id='tryLogo'>
                        <a href='https://chrome.google.com/webstore/detail/meteor-wallet/pcndjhkinnkaohffealmlmhaepkpmgkb' target="_blank"><Chrome/>  </a>
                        <a href='https://wallet.meteorwallet.app/' target="_blank">   <Web/></a>
                        <a href='#'><PlayStore className='inactive'/></a>
                        <a href='#'> <AppStore className='inactive'/> </a>
                    </div>
                </Container>

                <Container  id='footerSection' className='wows fadeInUp'>
                    <img src={BGGradient7} id='bgGradient7'/>
                    <img src={BGGradient8} id='bgGradient8'/>
                    <div id='footerHeading'>
                        <div>
                            <Logo className='footerlogo'/>
                            <p>The wallet of the future</p>
                        </div>
                        <div id='footerSocial'>
                            <a  href="https://twitter.com/MeteorWallet" target="_blank">
                                <img src={Twitter}/>
                            </a>
                            <a href="https://discord.com/invite/9D5V5j5nWr" target="_blank">
                                <img src={Discord}/>
                            </a>
                            <a href="https://t.me/MeteorWalletGroup" target="_blank">
                                <img src={Telegram}/>
                            </a>
                        </div>
                    </div>
                    <div id='footerBottom'>
                        <div id='footerCopyright'>
                            <h6>Copyright © 2024 METEOR LAB PTE. LTD. All Rights Reserved </h6>
                        </div>
                        <div id='footerLink'>
                            <a  href="https://github.com/Meteor-Wallet/meteor-public/blob/main/compliance/compliance-overview.md" target="_blank">Security</a>
                            <Nav.Link onClick={this.handleClick}>Support</Nav.Link>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeDsU9wDxz2davB9ik9TPTseg3piUV2hig-FddnRpX31YoEZw/viewform" target="_blank">Collaboration</a>
                            <a href="https://github.com/Meteor-Wallet/meteor-public" target="_blank">Integration</a>
                            <a href="https://meteorwallet.app/terms/privacy" target="_blank">Privacy & Terms</a>
                            <a href="https://drive.google.com/drive/u/2/folders/1pk5RLeYaQQu7X6FfQ9GnzGzTc9NyMInI" target="_blank">Brand Kit</a>

                        </div>
                    </div>
                </Container>


            </div>
        );
    }
}

export default HomePage;
