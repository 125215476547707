import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './HomePage';
import PolicyPage from './PolicyPage';
import TermsPage from './TermsPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/terms/privacy' element={<PolicyPage/>} />
        <Route path='/terms/terms-and-conditions' element={<TermsPage/>} />
      </Routes>
    </Router>
  );
};

export default App;